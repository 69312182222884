import React, { useState } from 'react';
import {
  Container,
  Typography,
  Checkbox,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const devices = [
  { name: 'LED Bulb', power: 10 },
  { name: 'Water Motor', power: 1500 },
  { name: 'Fridge', power: 150 },
  { name: 'Fan', power: 75 },
  { name: 'Air Conditioner (AC)', power: 1500 },
  { name: 'Laptop', power: 60 },
  { name: 'Wi-Fi Router', power: 5 },
];

function Solar() {
  const [deviceStates, setDeviceStates] = useState(
    devices.map((device) => ({ ...device, quantity: 0 }))
  );
  const [totalSolar, setTotalSolar] = useState(0);
  const [totalPower, setTotalPower] = useState(0);
  const [solarPanelWattage, setSolarPanelWattage] = useState(350);
  const [solarPanelPrice, setSolarPanelPrice] = useState(8700);
  const [totalSolarCost, setTotalSolarCost] = useState(0);
  const handleDeviceToggle = (index) => {
    const updatedDevices = [...deviceStates];
    updatedDevices[index].quantity = updatedDevices[index].quantity === 0 ? 1 : 0;
    setDeviceStates(updatedDevices);
    const power = updatedDevices.reduce(
      (total, device) => total + device.power * device.quantity,
      0
    );
    setTotalPower(power);
  };

  const calculateSolarCapacity = () => {
    const solarCapacityKW = totalPower / 1000;
    calculateTotalSolar();
    calculateTotalSolarCost();
    return solarCapacityKW.toFixed(2); // Round to two decimal places
  };

  const calculateTotalSolar = () => {
    const cnta = totalPower / solarPanelWattage;
    return Math.ceil(cnta.toFixed(2));
  };
  const calculateTotalSolarCost = () => {
    const cnta = totalPower / solarPanelWattage;
    const a = Math.ceil(cnta.toFixed(2)) ;
    const toatlCost = eval(a * solarPanelPrice);
    console.log('cnta',cnta)
    console.log('solarPanelPrice',solarPanelPrice)
    console.log('toatlCost',toatlCost)
    return toatlCost;
  };
  const handlePanelWatt = (val)=>{
    setSolarPanelWattage(val)
  }
  const handlePanelCost = (val)=>{
    setSolarPanelPrice(val)
  }
  return (
    <Container className="gradient-background">
      <Typography variant="h4" gutterBottom>
        Solar Panel Calculator
      </Typography>
      <Grid container spacing={2}>
        {deviceStates.map((device, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={device.name}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={8}>
                <label>
                  <Checkbox
                    checked={device.quantity > 0}
                    onChange={() => handleDeviceToggle(index)}
                  />
                  {device.name} ({device.power}W)
                </label>
              </Grid>
              <Grid item xs={4}>
                {device.quantity >= 0 && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-label">Qty</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      lable="Qty"
                      value={device.quantity}
                      onChange={(e) => {
                        const updatedDevices = [...deviceStates];
                        updatedDevices[index].quantity = e.target.value;
                        setDeviceStates(updatedDevices);
                        const power = updatedDevices.reduce(
                          (total, d) => total + d.power * d.quantity,
                          0
                        );
                        setTotalPower(power);
                      }}
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((quantity) => (
                        <MenuItem key={quantity} value={quantity}>
                          {quantity}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Watts per solar panel (adjust as needed)"
              variant="outlined"
              fullWidth
              value={solarPanelWattage}
              style={{ marginTop: '16px' }}
              onChange={(e)=>handlePanelWatt(e.target.value)}
            />  
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label="Price per solar panel (adjust as needed)"
              variant="outlined"
              fullWidth
              value={solarPanelPrice}
              style={{ marginTop: '16px' }}
              onChange={(e)=>handlePanelCost(e.target.value)}
            />  
          </Grid>
      </Grid>
      <TextField
        label="Total Power (W)"
        variant="outlined"
        fullWidth
        value={totalPower}
        disabled
        style={{ marginTop: '16px' }}
      />
      {totalPower > 0 && (
        <div>
          <TextField
            label="Solar Panel Capacity (kW)"
            variant="outlined"
            fullWidth
            value={calculateSolarCapacity()}
            disabled
            style={{ marginTop: '16px' }}
          />
          <TextField
            label="Required Solar Panel"
            variant="outlined"
            fullWidth
            value={calculateTotalSolar()}
            disabled
            style={{ marginTop: '16px' }}
          />
          <TextField
            label="Total Cost of Solar Panel (in Rs.)"
            variant="outlined"
            fullWidth
            value={calculateTotalSolarCost()}
            disabled
            style={{ marginTop: '16px' }}
          />
        </div>
      )}
      <div id="container-aa1997a0f3dc2d53000ff78afec4f7d9"></div>
    </Container>
  );
}

export default Solar;
